import React, {Component} from "react"
import {Col, Row} from 'react-bootstrap'
import StoryCard from './StoryCard'
import Jumbo from './Jumbo'


class StoryRowImage extends Component {
    constructor (props){   
       super(props);
       this.state = {
       feed: []
   };
   
 }
  componentDidMount(props) {
       return fetch(this.props.api)
       .then((response) => response.json())
       .then((responseJson) => {
        const feed =  responseJson;
        this.setState({feed: feed.items}); 
       })
       .catch((error) => {
         console.error(error);
       });         
 }
    render(props) {
      const feed = this.state.feed;
      console.log(feed);

      const cards = feed.map((feed, index) => {
        if(index>=0){
          return(       
          <StoryCard title={feed.title} image={feed.enclosure.link} image2={feed.thumbnail} date={feed.pubDate} link={feed.link} description={feed.description} />      
              )
            }
            else{
              return(
                null
              )
            }
          }
        );

      
    
        return (
        
          
          <Row className="rowtopper">
          <Col xs={12} md={3} className="my-auto">
          <Jumbo class={this.props.class} />
          </Col>
          <Col xs={12} md={9}>
          <div  className="cardscroller"> {cards} </div>
          </Col>       
        </Row>  
      )
        
    }
  }

export default StoryRowImage