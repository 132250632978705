import React from 'react'
import StoryRowImage from './StoryRowImage'
import StoryRowNoImage from './StoryRowNoImage'
import VideoRow from './VideoRow'

const golfchanapi =  `https://api.rss2json.com/v1/api.json?rss_url=http%3A%2F%2Fwww.golfchannel.com%2Frss%2F124552%2Ffeed.xml&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi&count=30`
const espnapi = `https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.espn.com%2Fespn%2Frss%2Fgolf%2Fnews&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi&count=30`
const bunkeredapi = `https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.bunkered.co.uk%2Ffeed&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi&count=12`
const golfcomapi = `https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.golf.com%2Ffeed%2F&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi&count=30`
const golfmonthlyapi = `https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.golf-monthly.co.uk%2Ffeed&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi&count=15`
const golfweekapi = `https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fgolfweek.com%2Ffeed%2F&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi&count=15`     
const pgayt = `https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.youtube.com%2Ffeeds%2Fvideos.xml%3Fuser%3Dpgatour&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi`
const eutouryt = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.youtube.com%2Ffeeds%2Fvideos.xml%3Fchannel_id%3DUCvZwbZt6YZQ4wj_7qyjPDZw&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi'
const europroyt = ' https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.youtube.com%2Ffeeds%2Fvideos.xml%3Fuser%3DPGAEuroProTour&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi'
function GolfCard() {

    return (

            <div className="container-fluid">                 
                <StoryRowImage api={golfchanapi} class={"jumbogolfchan"}/>
                <VideoRow api={pgayt} image={require("../assets/images/pga-logo.png")} />
                <StoryRowImage api={golfweekapi} class={"jumbogolfweek"}/>
                <StoryRowImage api={golfmonthlyapi} class={"jumbogolfmonthly"} />
                <VideoRow api={eutouryt} image={require("../assets/images/eutourtrans.png")} />
                <StoryRowImage api={golfcomapi} class={"jumbogolfcom"} />   
                <VideoRow api={europroyt} image={require("../assets/images/eupro.png")} />         
                <StoryRowNoImage api={espnapi} class={"jumboespn"} image={require("../assets/images/espn_alt.png")} />            
                <StoryRowNoImage api={bunkeredapi} class={"jumbobunk"} image={require("../assets/images/bunksq.png")} />
            </div>
    );
}

export default GolfCard;