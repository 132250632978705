import React, {useState} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import SharePopOver from './SharePopOver'
function StoryPreview(props){


  const [show, setShow] = useState(false);
  let artdescrip = props.description;
  let descripfilter = artdescrip.includes("<p", "&amp");
  let image = props.image
  
  return (
    
    <>
      <Button variant="secondary" onClick={() => setShow(true)}>
        Preview
      </Button>
      

      <Modal className="preview"
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header  closeButton>
        <img
              src={image ? props.image : props.image2}
              alt="article"          
          />
        </Modal.Header>
        <Modal.Title>
        <div dangerouslySetInnerHTML={{__html: props.title}}></div>
          </Modal.Title>
        <Modal.Body>
        <div className="paragraph" dangerouslySetInnerHTML={{__html: artdescrip}}></div>
        </Modal.Body>
        <h6>Published On: {props.date }
        <p className="previewpopover"><SharePopOver url={props.link}/></p></h6>      
        <Button href= {props.link} variant="secondary" target="_blank"> Read Full Story </Button>
      </Modal>
      
    </>
  );
}
  
export default StoryPreview