import React from 'react'
import { Link } from 'react-router-dom'
import { Row } from 'react-bootstrap'

function TopBar() {
  return (
        <div className="container-fluid topbar">
          <Row className="justify-content-center">
          <Link  to="/" >
              <img
              href="/"
              src={require("../assets/images/SPORT.png")}
              alt="sport zone logo" 
              height="50px"
              width="50px"
              />
          </Link>
          </Row>
        </div>
  )
}

export default TopBar




