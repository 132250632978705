import React from 'react'
import StoryRowNoImage from './StoryRowNoImage'
import StoryRowImage from './StoryRowImage'
import VideoRow from './VideoRow'


const boxnews = 'https://api.rss2json.com/v1/api.json?rss_url=http%3A%2F%2Fwww.boxingnewsonline.net%2Ffeed%2F&api_key=ylphc5dnpifen2miejvzvdl6bioxa1mdgzji82kj'
const ringmag = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.ringtv.com%2Ffeed%2F&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi'
const skyboxyt = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.youtube.com%2Ffeeds%2Fvideos.xml%3Fchannel_id%3DUC_JQGBtA7P0RwkRxd7xpJcA&api_key=ylphc5dnpifen2miejvzvdl6bioxa1mdgzji82kj'

function BoxingCards() {

    return (

            <div className="container-fluid">                
                
                <StoryRowImage api={ringmag} class={"jumboringmag"} />
                <VideoRow api={skyboxyt} image={require("../assets/images/sky-sports-logo.png")}/>
                <StoryRowNoImage api={boxnews} class={"jumboboxnews"} image={require("../assets/images/boxingnews.jpg")}/>
            </div>
    );
}

export default BoxingCards;