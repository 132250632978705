import React, {Component} from "react"
import {Col, Row} from 'react-bootstrap'
import ReactPlayer from 'react-player'


class VideoRow extends Component {
    constructor (props){   
       super(props);
       this.state = {
       feed: [],
       controls: true,
       
      
   };
   
 }
  componentDidMount(props) {
       return fetch(this.props.api)
       .then((response) => response.json())
       .then((responseJson) => {
        const feed =  responseJson;
        this.setState({feed: feed.items}); 
       })
       .catch((error) => {
         console.error(error);
       });         
 }
    render(props) {
      let today = new Date();
      let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
  
      const feed = this.state.feed;
      console.log(feed);
      const videos = feed.map( (feed, index) => {
        if(index){
          return(
            
            
                <ReactPlayer className="video"
                 url={feed.link} 
                 controls={this.state.controls}
                /> 
                                                       
                  
              )
            }
            else{
              return(
                null
              )
            }
          }
        );
     
      
    
        return (
          
        <div className="container headlinecard">
          <Row>
          <Col xs={12} md={4} className="my-auto">
          <h4>Latest Videos</h4>
            <p>{date}</p>
            <p>Brought to you by {this.props.publisher}</p>
            <h5>More from <a href={this.props.link}>{this.props.publisher}</a></h5>
          </Col> 
          <Col xs={12} md={8}>
            <div className="cardscroller">   
              {videos}          
            </div>  
          </Col>   
        </Row>
        </div>
        
          
         
           
      )
        
    }
    
  }

export default VideoRow