import React from 'react'
import StoryRowImage from './StoryRowImage'
import StoryRowNoImage from './StoryRowNoImage'

import VideoRow from './VideoRow'


const skysportsyt = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.youtube.com%2Ffeeds%2Fvideos.xml%3Fchannel_id%3DUCNAf1k0yIjyGu3k9BwAg3lg&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi'
const premierleagueyt = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.youtube.com%2Ffeeds%2Fvideos.xml%3Fchannel_id%3DUCG5qGWdu8nIRZqJ_GgDwQ-w&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi'
const goalapi = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.goal.com%2Ffeeds%2Fen%2Fnews&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi&count=20'
const fb365 = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.football365.com%2Ffeed&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi'
const ninemin = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.90min.com%2Fposts.rss&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi'

function FootballCards() {

    return (

            <div className="container-fluid">                
                
                <StoryRowImage api={goalapi} class={"jumbogoalcom"} /> 
                <VideoRow api={skysportsyt} image={require("../assets/images/sky-sports-logo.png")}/>
                <StoryRowNoImage api={fb365} class={"jumbofb365"} image={require("../assets/images/fb365.jpg")} />
                <VideoRow api={premierleagueyt} image={require("../assets/images/premlogo.png")}/>
                <StoryRowImage api={ninemin} class={"jumboninemin"} />
            </div>
    );
}

export default FootballCards;