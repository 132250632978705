import React from 'react'
import NBACards from '../components/NBACards'

function NBA() {
    return (
        <div className="homecardwrapper">
            <NBACards />
        </div>    
    );
}

export default NBA;