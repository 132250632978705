import React from 'react'
import Golf from './pages/Golf'
import F1 from './pages/F1'
import Football from './pages/Football'
import NBA from './pages/NBA'
import NFL from './pages/NFL'
import Boxing from './pages/Boxing'
import Esport from './pages/Esport'
import Home from './pages/Home'
import TopBar from './components/TopBar'
import Navigation from './components/Navigation'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
//Import Stylesheets
import './stylesheets/custom.scss';
import './stylesheets/App.css';

function App() {
    return (     
    <>
       <Router>     
        <TopBar />
            <Switch>
                <Route path="/" exact component={Home}/>
                <Route path="/football" exact component={Football}/>
                <Route path="/golf" exact component={Golf}/>
                <Route path="/f1" exact component={F1}/>
                <Route path="/nba" exact component={NBA}/>
                <Route path="/esport" exact component={Esport}/>
                <Route path="/boxing" exact component={Boxing}/>
                <Route path="/nfl" exact component={NFL}/>
            </Switch>
        <Navigation />
        </Router>
    </>
 );
}

export default App;