import React, {Component} from "react"
import {Col, Row} from 'react-bootstrap'
import ReactPlayer from 'react-player'
import Image from 'react-bootstrap/Image'


class VideoRow extends Component {
    constructor (props){   
       super(props);
       this.state = {
       feed: [],
       controls: true,
       
      
   };
   
 }
  componentDidMount(props) {
       return fetch(this.props.api)
       .then((response) => response.json())
       .then((responseJson) => {
        const feed =  responseJson;
        this.setState({feed: feed.items}); 
       })
       .catch((error) => {
         console.error(error);
       });         
 }
    render(props) {
      const feed = this.state.feed;
      console.log(feed);
      const videos = feed.map( (feed, index) => {
        if(index){
          return(
            
                <ReactPlayer className="video"
                 url={feed.link} 
                 controls={this.state.controls}
                 height="450px"
                
                />                                                  
                  
              )
            }
            else{
              return(
                null
              )
            }
          }
        );
     
      
    
        return (
          <Row className="justify-content-center videorow">
          <Col xs={12} md={3} className="my-auto">
          <Image src={this.props.image} fluid />
          </Col>
          <Col xs={12} md={7}>
            <div className="cardscroller">   
              {videos}          
            </div>  
          </Col>   
        </Row>  
      )
        
    }
    
  }

export default VideoRow