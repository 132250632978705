import React from "react"
import Jumbotron from 'react-bootstrap/Jumbotron'



function Jumbo(props) {

    return (

        
        
        
            
                <Jumbotron className={props.class}></Jumbotron>
            
       
                 

           

              
          



    );
}

export default Jumbo;

