import React from 'react'
import StoryRowNoImage from './StoryRowNoImage'
import VideoRow from './VideoRow'

const espnnba = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.espn.com%2Fespn%2Frss%2Fnba%2Fnews&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi&count=15'
const nbayt = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.youtube.com%2Ffeeds%2Fvideos.xml%3Fuser%3Dnba&api_key=ylphc5dnpifen2miejvzvdl6bioxa1mdgzji82kj'

function NBACards() {

    return (

            <div className="container-fluid">                
                
                <StoryRowNoImage api={espnnba} class={"jumboespn"} image={require("../assets/images/espn_alt.png")} />
                <VideoRow api={nbayt} image={require("../assets/images/nba.png")}/>

            </div>
    );
}

export default NBACards;