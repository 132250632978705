import React from 'react'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  
} from "react-share";




function SharePopOver(props){

  const popover = (
    <Popover >     
      <Popover.Content className="sharepopover">
      <FacebookShareButton
      url={props.url}>
            <FacebookIcon
              size="1.5rem"/>
          </FacebookShareButton>
          <TwitterShareButton
      url={props.url}>
            <TwitterIcon
              size="1.5rem"/>
          </TwitterShareButton>
          <WhatsappShareButton
      url={props.url}>
            <WhatsappIcon
              size="1.5rem"/>
          </WhatsappShareButton>
          <EmailShareButton
      url={props.url}>
            <EmailIcon
              size="1.5rem"/>
          </EmailShareButton>
      </Popover.Content>
     
    </Popover>
  );
  
    
      return (
        
        
        <OverlayTrigger trigger="click" rootClose placement="right" overlay={popover}>
        <img 
                            href="/golf"
                            src={require("../assets/images/share-symbol.png")}
                            alt="share"
                            width="15px"
                            height="15px"
                          />
  </OverlayTrigger>
  
       
      );
    }
      
    export default SharePopOver