import React from 'react'
import Card from 'react-bootstrap/Card'
import StoryPreview from './StoryPreview'

function StoryCard(props){

let image = props.image

  return (
    
    <>
      <Card className="cardstyle" >
            <Card.Img src={image ? props.image : props.image2} alt="img" />
            <Card.Body>
                <Card.Subtitle className="cardsubtitle"> {props.categories} </Card.Subtitle>
                <Card.Title> <div dangerouslySetInnerHTML={{__html: props.title}}></div>
                </Card.Title>
                <StoryPreview title={props.title} image={props.image} image2={props.image2} date={props.date} link={props.link} description={props.description}/>                                                     
            </Card.Body>
          </Card>   
    </>
  );
}
  
export default StoryCard