import React from 'react'
import StoryRowNoImage from './StoryRowNoImage'
import StoryRowImage from './StoryRowImage'

const espnesport = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.espn.com%2Fespn%2Frss%2Fesports%2Fnews&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi'
const f1esports = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Ff1esports.com%2Fnews%2Ffeed%2F&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi'
const estn = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Festnn.com%2Ffeed%2F&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi'
const eso = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fesportsobserver.com%2Ffeed%2F&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi'
const esi = ' https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fesportsinsider.com%2Ffeed%2F&api_key=ylphc5dnpifen2miejvzvdl6bioxa1mdgzji82kj&count=15'
function EsportCards() {

    return (

            <div className="container-fluid"> 
                <StoryRowNoImage api={eso} class={"jumboteo"} image={require("../assets/images/teo.png")}/>       
                <StoryRowImage api={esi} class={"jumboesi"} /> 
                <StoryRowNoImage api={espnesport} class={"jumboespn"} image={require("../assets/images/espn_alt.png")}/>
                <StoryRowNoImage api={f1esports} class={"jumbof1esport"} image={require("../assets/images/f1esport.jpg")}/>
                <StoryRowNoImage api={estn} class={"jumboestn"} image={require("../assets/images/estn.png")} />

            </div>
    );
}

export default EsportCards;