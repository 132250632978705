import React from 'react'
import NFLCards from '../components/NFLCards'

function NFL() {
    return (
        <div className="homecardwrapper">
            <NFLCards />
        </div>    
    );
}

export default NFL;