import React from 'react'
import GolfCard from '../components/GolfCards'

function Golf() {
    return (
        <div className="homecardwrapper">
            <GolfCard />
        </div>    
    );
}

export default Golf;