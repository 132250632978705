import React from 'react'
import { Row, Col } from 'react-bootstrap'
import MainHeadlineCard from './MainHeadlineCard'
import SportHeadlineCard from './SportHeadlineCard'
import VideoRowHeadlines from './VideoRowHeadlines'

const mainheadlinesuk = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.skysports.com%2Frss%2F12040&api_key=ylphc5dnpifen2miejvzvdl6bioxa1mdgzji82kj'
const espnf1api = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.espn.co.uk%2Fespn%2Frss%2Ff1%2Fnews&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi&count=5'
const golfchanapi =  `https://api.rss2json.com/v1/api.json?rss_url=http%3A%2F%2Fwww.golfchannel.com%2Frss%2F124552%2Ffeed.xml&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi&count=5`
const goalapi = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.goal.com%2Ffeeds%2Fen%2Fnews&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi&count=5'
const espnnba = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.espn.com%2Fespn%2Frss%2Fnba%2Fnews&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi&count=5'
const skysportsyt = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.youtube.com%2Ffeeds%2Fvideos.xml%3Fchannel_id%3DUCTU_wC79Dgi9rh4e9-baTqA&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi'
const ringmag = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.ringtv.com%2Ffeed%2F&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi&count=5'
const fansided = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Ffansided.com%2Fnfl%2Ffeed%2F&api_key=ylphc5dnpifen2miejvzvdl6bioxa1mdgzji82kj&count=5'
const nfl = "NFL"
const boxing = "Boxing"
const football = "Football"
const golf = "Golf"
const f1 = "F1"
const nba = "NBA"

function HomeCards() {

    return (
        <div className="container-fluid">
            <Row className="rowtopper">
                <Col md={8} xs={12}>
                <MainHeadlineCard api={mainheadlinesuk} category={"UK"}/>
                <VideoRowHeadlines api={skysportsyt} publisher={"Sky Sports"} link={"https://www.skysports.com/"}/>
                <SportHeadlineCard api={espnnba} category={nba}  publisher={"ESPN"} linkto={"/nba"} />
                <SportHeadlineCard api={ringmag} category={boxing}  publisher={"The Ring"} linkto={"/boxing"} />

                </Col>
                <Col md={4} xs={12}>
                <SportHeadlineCard api={goalapi} category={football} publisher={"Goal.com"} linkto={"/football"} />
                <SportHeadlineCard api={golfchanapi} category={golf}  publisher={"Golf Channel"} linkto={"/golf"} />
                <SportHeadlineCard api={espnf1api} category={f1}  publisher={"ESPN"} linkto={"/f1"} />
                <SportHeadlineCard api={fansided} category={nfl}  publisher={"FanSided"} linkto={"/nfl"} />
                </Col>
            </Row>  
        </div>
            
                    
    );
}

export default HomeCards;