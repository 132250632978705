import React from 'react'
import EsportCards from '../components/EsportCards'

function Esport() {
    return (
        <div className="homecardwrapper">
            <EsportCards />
        </div>    
    );
}

export default Esport;