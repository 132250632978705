import React from 'react'
import HomeCards from '../components/HomeCards'

function Home() {
    return (
        <div className="homecardwrapper">
         <HomeCards />
        </div>    
    );
}

export default Home;