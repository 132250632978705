import React from 'react'
import StoryRowNoImage from './StoryRowNoImage'
import VideoRow from './VideoRow'

const nflyt = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.youtube.com%2Ffeeds%2Fvideos.xml%3Fuser%3Dnfl&api_key=ylphc5dnpifen2miejvzvdl6bioxa1mdgzji82kj'
const espnnfl = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.espn.com%2Fespn%2Frss%2Fnfl%2Fnews&api_key=ylphc5dnpifen2miejvzvdl6bioxa1mdgzji82kj'
const fansided = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Ffansided.com%2Fnfl%2Ffeed%2F&api_key=ylphc5dnpifen2miejvzvdl6bioxa1mdgzji82kj'


function NFLCards() {

    return (

            <div className="container-fluid">                
                
                <StoryRowNoImage api={fansided} class={"jumbofansided"} image={require("../assets/images/fansided.jpg")} />
                <VideoRow api={nflyt} image={require("../assets/images/nfl.png")}/>
                <StoryRowNoImage api={espnnfl} class={"jumboespn"} image={require("../assets/images/espn_alt.png")} />

            </div>
    );
}

export default NFLCards;