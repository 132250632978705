import React,{Component} from "react";


class Search extends Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://cse.google.com/cse.js?cx=003524294105462361789:xeklew9chrv";
    this.div.appendChild(script);
  }
  render() {
    return (
      <div class="search" ref={el => (this.div = el)}>  
        {/* Script is inserted here */}
        <div class="gcse-search"></div>
      </div>
    );
  }
}

export default Search;
