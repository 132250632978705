import React, {Component} from "react"
import {Row, Col, Image} from 'react-bootstrap'
import SharePopOver from "./SharePopOver";

class MainHeadlineCard extends Component {
    constructor (props){   
       super(props);
       this.state = {
       feed: []
    };
   }
  componentDidMount(props) {
       return fetch(this.props.api)
       .then((response) => response.json())
       .then((responseJson) => {
        const feed =  responseJson;
        this.setState({feed: feed.articles || feed.items}); 
       })
       .catch((error) => {
         console.error(error);
       }
      );
     }
    render(props) {
    let today = new Date();
    let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
  

    const feed = this.state.feed;
    console.log(feed);      
    const list = feed.map( (feed, index) => {
        if(index===0){
          return(
            
                <div className="headlinemain">
                    <Row>
                    <Col md={8}  className="my-auto">
                        <h5>{feed.title}</h5>
                        <h6><a href={feed.url || feed.link} target="_blank">Read More </a>
                          <SharePopOver url={feed.link || feed.url} />
                        </h6>
                        
                    </Col>
                    <Col md={4}><Image src={feed.enclosure.link} fluid/></Col>           
                    </Row>
                </div>
                
                
              )
            }
            else{
              return(
                
                <ul>
                    <li>{feed.title}</li>
                    <h6><a href={feed.url || feed.link}>Read More </a>
                    <SharePopOver  url={feed.link || feed.url}/>
                    </h6>
                </ul>
              )
            }
          }
        );   
    return (        
        
                    <div className="headlinecard">
                        <h4>Latest Headlines - {this.props.category}</h4>
                        <p>Brought to you by Sky Sports News</p>
                        <p>{date}</p>
                        {list}
                    </div>
      )
    }
  }
export default MainHeadlineCard