import React from 'react'
import { Link } from 'react-router-dom'
import { Navbar, Nav} from 'react-bootstrap'
import Search from './Search'
import ScrollButton from './ScrollButton'


function Navigation() {
  return (

  
    <Navbar className="Navbar" bg="dark" variant="dark" expand="lg" fixed="bottom" collapseOnSelect>
      
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
          <Link to="/football"><Nav.Link eventKey="1" href=" ">Football</Nav.Link></Link>
          <Link to="/golf"><Nav.Link eventKey="2" href=" ">Golf</Nav.Link></Link>        
          <Link to="/f1"><Nav.Link  eventKey="3" href=" ">F1</Nav.Link></Link>
          <Link to="/nba"><Nav.Link eventKey="4" href=" ">NBA</Nav.Link></Link>
          <Link to="/esport"><Nav.Link eventKey="5" href=" ">Esport</Nav.Link></Link>
          <Link to="/nfl"><Nav.Link eventKey="6" href=" ">NFL</Nav.Link></Link>
          <Link to="/boxing"><Nav.Link eventKey="7" href=" ">Boxing</Nav.Link></Link>
      </Navbar.Collapse>
      <ScrollButton />
    </Navbar>
    
  
  )
}

export default Navigation