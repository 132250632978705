import React from 'react'
import F1Cards from '../components/F1Cards'

function F1() {
    return (
        <div className="homecardwrapper">
            <F1Cards />
        </div>    
    );
}

export default F1;