import React from 'react'
import StoryRowNoImage from './StoryRowNoImage'
import VideoRow from './VideoRow'

const autosportapi = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.autosport.com%2Frss%2Ffeed%2Ff1&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi'
const f1ytapi = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.youtube.com%2Ffeeds%2Fvideos.xml%3Fuser%3DFormula1&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi'
const espnf1api = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.espn.co.uk%2Fespn%2Frss%2Ff1%2Fnews&api_key=viqh7z0bsjp7ooew3hfxicpb1wr4ezmsdcboehzi'

function F1Cards() {

    return (

            <div className="container-fluid">                
                
                <StoryRowNoImage api={autosportapi} class={"jumboautosport"} image={require("../assets/images/autosport.jpg")} />
                <VideoRow api={f1ytapi} image={require("../assets/images/f1.png")}/>
                <StoryRowNoImage api={espnf1api} class={"jumboespn"} image={require("../assets/images/espn_alt.png")} />
                
            </div>
    );
}

export default F1Cards;