import React from 'react'
import FootballCards from '../components/FootballCards'

function Football() {
    return (
        <div className="homecardwrapper">
            <FootballCards />
        </div>    
    );
}

export default Football;