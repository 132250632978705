import React from 'react'
import BoxingCards from '../components/BoxingCards'

function Boxing() {
    return (
        <div className="homecardwrapper">
            <BoxingCards />
        </div>    
    );
}

export default Boxing;